import React, { useEffect, useCallback, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Redirect, withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import HeaderList from "../component/layout/HeaderList";
import { Footer } from "../component/layout/Footer";

import { authenticate, logout } from "../redux/actions/Auth";
import { Loader } from "../component/common/Loader";
import { VRSConst } from "./Constants";
import { HandelError } from "../component/common/HandleError";
import ErrorBoundary from "../component/common/ErrorBoundry";
import { SWUpdate } from "../component/common/SWUpdate";

function App({ children, location }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const permission = useSelector((state) => state.AuthReducer.permission);
  const userDetails = useSelector((state) => state.AuthReducer.user);
  const authenticated = useRef(false);
  // useEffect(() => {
  //   setToken(
  //     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImV4cCI6IjEyMDAifQ.eyJDdXN0b21lcklEIjo3MCwiTG9nZ2VkSW5TdGFmZklEIjowLCJDdXN0b21lck5hbWUiOiJKaWxsIE1hc29uIiwiQ3JlYXRlRGF0ZVRpbWUiOiIyMDIyMDIyODA4MTUifQ.OUq-T1UGtdJxdxCkCkL75jMZYDLSNuUnFM_vNh5kUHs"
  //   );
  // }, []);
  const callAuthenticate = useCallback(async () => {
    try {
      // comment out the checking of valid key
      if (!authenticated.current) {
        setLoading(true);
        const res = await authenticate(dispatch);
        if (res) {
          setLoading(false);
        }
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
      logout();
      toast.error(<HandelError error={e} />, { autoClose: 2000 });
    }
  }, [dispatch]);
  useEffect(() => {
    const removeLoader = () => {
      const loader = document.getElementById("parentLoader");
      if (loader) {
        loader.remove();
      }
    };
    removeLoader();
  }, []);
  useEffect(() => {
    /**
     * This method checks for the authentication of the user
     */
    if (!location.pathname.includes("servicer-dashboard")) {
      callAuthenticate();
    }
  }, [callAuthenticate, dispatch, location]);
  /**
   * Call the method for refresh token API call
   * @returns {Promise<void>}
   */
  const callRefreshToken = async () => {
    try {
      // await tokenRefresh();
    } catch (error) {
      toast.error(<HandelError error={error} />, { autoClose: 2000 });
    }
  };
  // schedule to call refresh token in every 55 minutes to refresh the access token
  useEffect(() => {
    const interval = setInterval(() => {
      // method call for refresh token
      callRefreshToken();
    }, VRSConst.refreshTime * 1000);
    return () => clearInterval(interval);
  }, []);

  // offline usage message
  React.useEffect(() => {
    const offlineOnlineInfo = (networkStatus) => {
      if (networkStatus) {
        toast.success("You are back online", {
          className: "custom-toast",
          autoClose: 2000,
        });
      } else {
        if (VRSConst.toastList.size >= VRSConst.MAX_TOAST) {
          toast.dismiss();
          VRSConst.toastList.clear();
        }
        if (VRSConst.toastList.size < VRSConst.MAX_TOAST) {
          const id = toast.info(
            "You are offline. Functionalities not available",
            {
              className: "custom-toast",
              onClose: () => {
                VRSConst.toastList.delete(id);
              },
            }
          );
        }
      }
    };
    window.addEventListener("online", () => offlineOnlineInfo(true));
    window.addEventListener("offline", () => offlineOnlineInfo(false));
    return () => {
      // window.removeEventListener('online');
      // window.removeEventListener('offline');
    };
  }, []);
  return (
    <div>
      {location.pathname === "/" && <Redirect to={VRSConst.mainPage} />}
      <Loader width={7} isLoading={loading} />
      {!loading && (
        <div>
          <ErrorBoundary>
            <SWUpdate />
            <HeaderList permission={permission} userDetails={userDetails} />
            <div className="container-fluid content-block">{children}</div>
            <Footer />
          </ErrorBoundary>
        </div>
      )}
      <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
    </div>
  );
}

export default withRouter(App);

App.propTypes = {
  children: PropTypes.instanceOf(Array),
  location: PropTypes.objectOf(PropTypes.string),
};
App.defaultProps = {
  children: [],
  location: "",
};
