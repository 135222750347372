import * as RoutePage from './index';
import {VRSConst} from '../Constants';

const RouteList = {
  mobileApp: {
    path: '/servicer-dashboard',
    component: RoutePage.MobileAppRoute,
    mobileRoutes: [],
  },
  mobileRoutes: [],
  issueApp: {
    path: '/issue-form',
    component: RoutePage.IssueAppRoute,
  },
  translationApp: {
    path: '/translation',
    component: RoutePage.TranslationRoute,
    routes: [
      {
        path: "/translation",
        component: RoutePage.TranslatePage,
      },
      {
        path: '/translation/locale/:localeId?',
        component: RoutePage.LocaleDetails,
      },
    ],
  },
  routes: [
    {
      path: `${VRSConst.mainPage}`,
      component: RoutePage.IntegrationsContainer,
    },
    {
      path: '/aboutQB/:id?',
      component: RoutePage.AboutQBContainer,
    },
    {
      path: '/MapProperties/:filter?',
      component: RoutePage.MapPropertiesContainer,
    },
    {
      path: '/MapPayRoll',
      component: RoutePage.MapPayRoll,
    },
    {
      path: '/MapStaffs/:filter?',
      component: RoutePage.MapStaffsContainer,
    },
    {
      path: '/MapTaskRule/:filter?',
      component: RoutePage.MapTaskRuleContainer,
    },
    {
      path: '/BillingApprove/:filter?',
      component: RoutePage.BillingApproveContainer,
    },
    {
      path: '/TimeTrackingApprove/:filter?',
      component: RoutePage.TimeTrackingApprovalContainer,
    },
    {
      path: '/SyncLog/:filter?',
      component: RoutePage.SyncLogContainer,
    },
    {
      path: '/SyncLogDetails/:syncParam?',
      component: RoutePage.SyncLogDetailsContainer,
    },
    {
      path: '/QBOnline',
      component: RoutePage.QBOnlineContainer,
    },
    {
      path: '/TaskApprove/:filter?',
      component: RoutePage.TaskApproveContainer,
    },
  ],
};
export default RouteList;
