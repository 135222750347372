import {getPermission} from "./TokenManager";
import {VRSConst} from "../app/Constants";

/**
 * Compares module permission in FE and BE
 * @param module
 * @param name
 * @return {boolean}
 */
export const checkModulePermission = (module, name = "permission") => {
  const permission = getPermission(name);
  // splitting the module value by '|'
  const moduleArray = module.split('|');
  // Checking if the module array has more than one value then checking each module permission
  if (moduleArray.length > 1) {
    moduleArray.map(
      (value) => (permission
        && (
          module === VRSConst.module.ALLOW_MASTER
          || parseInt(permission[value], 10)
        )
      ),
    );
  }
  return (permission
      && (
        module === VRSConst.module.ALLOW_MASTER
        || parseInt(permission[moduleArray[0]],10)
      )
  );
};
