export const VRSConst = {
  url: process.env.REACT_APP_PHP_API_URL,
  version: "v1",
  suffix: "vrs",
  refreshTime: 3360, // in seconds
  refreshMobileTime: 3360,
  checkVersionTime: 300,
  logoutTime: 540,
  getUrl: () => `${VRSConst.url}/${VRSConst.version}/${VRSConst.suffix}`,
  module: {
    ALLOW_MASTER: "AllowAlways",
    ALLOW_ADMIN_ACCESS: "AllowAdminAccess",
    ALLOW_MAANGE: "AllowManage",
    ALLOW_CONNECTED_STRIPE_ID: "ConnectedStripeAccountID",
    ALLOW_USE_QUICKBOOKS: "UseQuickbooks",
    ALLOW_REPORTS: "AllowReports",
    ALLOW_SETUP_ACCESS: "AllowSetupAccess",
    ALLOW_ACCOUNT_ACCESS: "AllowAccountAccess",
    ALLOW_ISSUE_ACCESS: "AllowIssuesAccess",
    ALLOW_QUICK_REPORTS: "AllowQuickReports",
    ALLOW_SCHEDULE_ACCESS: "AllowScheduleAccess",
    ALLOW_MASTER_CALENDER: "AllowMasterCalendar",
    ALLOW_TRACKING: "AllowTracking",
    ALLOW_TIME_TRACKING: "TimeTracking",
    ALLOW_REGION_GROUP: "RegionGroup",
    ALLOW_PROPERTY_GROUP: "PropertyGroup",
    ALLOW_PIECE_PAY: "PiecePay",
    ALLOW_I_CAL_ADD_ON: "ICalAddOn",
    ALLOW_EDIT_BOOKINGS: "AllowEditBookings",
    ALLOW_TRACK_LABOR_MATERIAL: "TrackLaborOrMaterials",
    ALLOW_INTEGRATION_COMPANY_ID: "IntegrationCompanyID",
    ALLOW_ALERT_ON_DAMAGE: "AlertOnDamage",
    ALLOW_ALERT_ON_MAINTAINACE: "AlertOnMaintainace",
    ALLOW_HAS_NOTE: "HasNote",
    ALLOW_HAS_PROPERTY: "HasProperty",
    ALLOW_HAS_PUBLIC_API_ACCESS: "HasPublicApiAccess",
  },
  cookieName: process.env.REACT_APP_CFJWT_COOKIE_NAME,
  menuRedirectedURL: process.env.REACT_APP_COLDFUSION_WEBSITE_URL,
  menuHelpRedurectedURL: "https://help-teams.operto.com/",
  reactAppVersion: process.env.REACT_APP_CODEBUILD_BUILD_NUMBER,
  integrationURL: process.env.REACT_APP_STAFF_DASHBOARD_INTEGRATION_URL,
  currentURL: process.env.REACT_APP_STAFF_DASHBOARD_URL,

  s3ImgUrl: `${process.env.REACT_APP_S3_BUCKET_URL}/media`,
  s3BucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  s3BucketUrl: process.env.REACT_APP_S3_BUCKET_URL,

  awsRegion: process.env.REACT_APP_STAFF_DASHBOARD_AWS_REGION,
  awsApiVersion: process.env.REACT_APP_STAFF_DASHBOARD_AWS_API_VERSION,
  awsAccessKey: process.env.REACT_APP_STAFF_DASHBOARD_AWS_ACCESS_KEY,
  awsSecretKey: process.env.REACT_APP_STAFF_DASHBOARD_AWS_SECRET_KEY,

  mainPage: "/integrations",
  dashboardPage: "/servicer/dashboard",
  perPageLimit: 20,
  startDate: "1970-01-01",
  publicUrl: process.env.REACT_APP_STAFF_DASHBOARD_URL,
  churnZeroUrl: process.env.REACT_APP_CHURNZERO_URL,
  churnZeroAppKey: process.env.REACT_APP_CHURNZERO_APP_KEY,
  isFullStoryEnabled: process.env.REACT_APP_FULLSTORY_ENABLED,
  fullStoryOrgId: process.env.REACT_APP_FULLSTORY_ORG_ID,
  bulkStatus: {
    Excluded: 0,
    Approved: 1,
    New: 2,
  },
  filter: {
    STAFF_FILTER: "MapStaffs",
    TASK_RULE_FILTER: "MapTaskRule",
  },
  logStatus: {
    Success: 1,
    Failed: 0,
  },
  BILLING_FILTER: "BILLING_FILTER",
  TIME_TRACKING_FILTER: "TIME_TRACKING_FILTER",
  ZAPIER_FILTER: "ZAPIER_FILTER",
  IntegrationType: {
    QUICKBOOKS_ENTERPRISE: 0,
    QUICKBOOKS_PRO: 1,
    QUICKBOOKS_ONLINE: 2,
  },
  TimeTrackingType: {
    TIME_TRACK_BY_DAYS: 0,
    TIME_TRACK_BY_TASKS: 1,
  },
  toastList: new Set(),
  MAX_TOAST: 1,
  MAX_MULTIPLE_IMAGE_OFFLINE: 10,
  zapierStatus: {
    Excluded: 0,
    Approved: 1,
    New: 2,
  },
  IntegrationName: {
    QUICKBOOKS: "QuickBooks",
    ZAPIER: "Zapier",
  },
  tableSortType: {
    ASC: "asc",
    DESC: "desc",
  },
};
