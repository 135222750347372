import {isEmpty} from 'lodash';
import initialState from './InitialState';
import {
  SET_CURRENT_USER,
  AUTHENTICATE,
  SET_USER_PERMISSION,
  SET_CURRENT_MOBILE_USER,
  SET_CURRENT_MOBILE_TOKEN,
  START_TASK_CHANGE_AUTH, CLOCK_IN_OUT,
  SET_CURRENT_USER_LOCALE,
  SET_CURRENT_ISSUE_USER,
} from '../actions/ActionTypes';
import {getLoggedInStaff, getPermission, getUserFromToken} from "../../utils/TokenManager";
import {changeTimeZone} from "../../utils/Client";

/**
 * Manipulate store to set authentication details
 * @param state
 * @param action
 * @returns {{}|AuthReducer.props|{auth}}
 * @constructor
 */
const AuthReducer = (state = initialState.auth, action) => {
  switch (action.type) {
    case SET_CURRENT_USER: {
      const authState = { ...state };
      authState.isAuthenticated = !isEmpty(action.authData);
      authState.user = action.authData;
      return {...state, ...authState};
    }
    case SET_USER_PERMISSION: {
      const authState = { ...state };
      authState.permission = action.permissionData;
      return {...state, ...authState};
    }
    case SET_CURRENT_USER_LOCALE: {
      const authState = { ...state };
      authState.localeID = action.localeData;
      return {...state, ...authState};
    }
    case AUTHENTICATE: {
      const loginState = {...state};
      if (!isEmpty(getLoggedInStaff())) {
        // checks for existance of token
        const user = getUserFromToken();
        const permission = getPermission("permission");
        loginState.isAuthenticated = !isEmpty(user);
        loginState.user = user;
        loginState.permission = permission;
        action.auth();
      }
      return {...state, ...loginState};
    }
    case SET_CURRENT_MOBILE_USER: {
      const authState = { ...state };
      authState.muser = action.auth;
      return {...state, ...authState};
    }
    case SET_CURRENT_MOBILE_TOKEN: {
      const authState = { ...state };
      authState.mtoken = action.token;
      return {...state, ...authState};
    }
    case START_TASK_CHANGE_AUTH: {
      return {
        ...state,
        muser: {
          ...state.muser,
          TimeClockDays: action.TimeClockTasks ? action.TimeClockTasks : state.muser.TimeClockDays,
          TimeClockTasks: action.TimeClockTasks,
          ClockedIn: state.muser.ClockedIn,
        },
      };
    }
    case CLOCK_IN_OUT: {
      return {
        ...state,
        muser: {
          ...state.muser,
          TimeClockDays: action.ClockInOut.ClockInOut,
          ClockedIn: action.ClockInOut.ClockInOut ? changeTimeZone(action.ClockInOut.DateTime, state.muser.Region, 'hh:mm A') : null,
        },
      };
    }
    case SET_CURRENT_ISSUE_USER: {
      const authState = { ...state };
      authState.iuser = action.iauth;
      return {...state, ...authState};
    }
    default: {
      return state;
    }
  }
};

export default AuthReducer;
