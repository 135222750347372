import initialState from './InitialState';
import {SET_ALL_STAFFS_DETAILS, SET_ALL_EMPLOYEES_DETAILS} from '../actions/ActionTypes';

const MapStaffReducer = (state = initialState.mapStaffs, action) => {
  switch (action.type) {
    case SET_ALL_STAFFS_DETAILS: {
      return {
        ...state,
        staffs: action.staffDetails,
      };
    }
    case SET_ALL_EMPLOYEES_DETAILS: {
      return {
        ...state,
        employees: action.employeesDetails,
      };
    }
    default:
      return state;
  }
};
export default MapStaffReducer;
