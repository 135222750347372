import initialState from './InitialState';
import {UNSCHEDULED_PROPERTIES, UNSCHEDULED_PROPERTY_DETAILS, UNSCHEDULED_TASK_DETAILS} from "../actions/ActionTypes";

const UnscheduledTaskReducer = (state = initialState.unscheduledTask, action) => {
  switch (action.type) {
    case UNSCHEDULED_PROPERTIES: {
      return {
        ...state,
        properties: action.properties,
      };
    }
    case UNSCHEDULED_TASK_DETAILS: {
      return {
        ...state,
        taskDetails: action.taskDetails,
      };
    }
    case UNSCHEDULED_PROPERTY_DETAILS: {
      return {
        ...state,
        property: action.propertyDetails,
        propertyCache: {...state.propertyCache, ...action.cache},
      };
    }
    default: return state;
  }
};
export default UnscheduledTaskReducer;
