import initialState from './InitialState';
import {
  SET_ALL_BILLABLE_TASKS,
  CHANGE_BILLABLE_STATUS,
  CHECK_BILLABLE_ITEM,
  CHECK_ALL_BILLABLE_ITEM,
  UNDO_SELECTED_BILLABLE_ITEMS,
  RESET_BILLING_APPROVE,
} from '../actions/ActionTypes';
import {
  changeStatus,
  checkAllItems,
  checkItem,
  undoSelected,
} from "../../utils/Helper";

const BillingApproveReducer = (state = initialState.billingApprove, action) => {
  switch (action.type) {
    case SET_ALL_BILLABLE_TASKS: {
      return {
        ...state,
        billableTasks: action.tasksDetails,
      };
    }
    case CHANGE_BILLABLE_STATUS: {
      let result = state.billableTasks.Details;
      if (state.billableTasks.Details) {
        result = changeStatus(state.billableTasks.Details, action.taskItem, action.status, 'TaskID');
      }
      const billableTask = {...state.billableTasks};
      billableTask.Details = result;
      return {
        ...state,
        billableTasks: billableTask,
      };
    }
    case CHECK_BILLABLE_ITEM: {
      let result = state.billableTasks.Details;
      if (state.billableTasks.Details) {
        result = checkItem(state.billableTasks.Details, action.taskItem, 'TaskID');
      }
      const billableTask = {...state.billableTasks};
      billableTask.Details = result;
      return {
        ...state,
        billableTasks: billableTask,
      };
    }
    case CHECK_ALL_BILLABLE_ITEM: {
      let result = state.billableTasks.Details;
      if (state.billableTasks.Details) {
        result = checkAllItems(state.billableTasks.Details, action.checkAll);
      }
      const billableTask = {...state.billableTasks};
      billableTask.Details = result;
      return {
        ...state,
        billableTasks: billableTask,
      };
    }
    case UNDO_SELECTED_BILLABLE_ITEMS: {
      let result = state.billableTasks.Details;
      if (state.billableTasks.Details) {
        result = undoSelected(state.billableTasks.Details);
      }
      const billableTask = {...state.billableTasks};
      billableTask.Details = result;
      return {
        ...state,
        billableTasks: billableTask,
      };
    }
    case RESET_BILLING_APPROVE: {
      let result = state.billableTasks.Details;
      if (state.billableTasks.Details) {
        result = state.billableTasks.Details.map(({prevStatus, checked, ...item}) => item);
      }
      const billableTask = {...state.billableTask};
      billableTask.Details = result;
      return {
        ...state,
        billableTasks: billableTask,
      };
    }
    default:
      return state;
  }
};
export default BillingApproveReducer;
