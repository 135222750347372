import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { isObject } from "lodash";
import { VRSErrorConst } from "../../app/ErrorConstants";
import { connectQBOnline } from "../../redux/interactions/IntegrationsInteraction";
import { logout } from "../../redux/actions/Auth";
import { clearMobileToken } from "../../utils/TokenManager";
import { errorEmail } from "../../utils/Helper";

export class HandelError extends PureComponent {
  ErrorDetails = () => {
    const { error } = this.props;
    if (isObject(error)) {
      switch (error.ReasonCode) {
        case VRSErrorConst.UNAUTHORISED_ACCESS: {
          logout();
          break;
        }
        case VRSErrorConst.UNRECOGNISED_FIELD_TYPE:
        case VRSErrorConst.UNRECOGNISED_INTEGRATION_ID:
        case VRSErrorConst.NO_CONTENT_CACHED:
        case VRSErrorConst.INACTIVE_USER:
        case VRSErrorConst.QB_PAYLOAD_ERROR:
        case VRSErrorConst.RATE_LIMIT_EXCETED: {
          return error.ReasonText;
        }
        case VRSErrorConst.TOKEN_EXPIRED: {
          if (window.navigator.onLine) {
            clearMobileToken().then(() => {
              setTimeout(() => {
                window.location.reload();
              }, 1500);
            });
          }
          return `${error.ReasonText}. Page will get refreshed.`;
        }
        case VRSErrorConst.TOKEN_DUPLICATE: {
          if (window.navigator.onLine) {
            clearMobileToken().then(() => {
              setTimeout(() => {
                window.location.reload();
              }, 1500);
            });
            return `${error.ReasonText}. Page will get refreshed.`;
          }
          return error.ReasonText;
        }
        case VRSErrorConst.UNPROCESSABLE_DATA: {
          connectQBOnline();
          return `Connecting to Quickbooks to authenticate again`;
          // return error.ReasonText;
        }
        default: {
          // triggerStageEmail(request);
          if (
            error.message !== "Network Error" &&
            error.message !== "timeout of 0ms exceeded" &&
            error.message !== "Request aborted" &&
            error.message !== "SecurityError" &&
            error.message !== "The operation is insecure"
          ) {
            errorEmail(error);
            return `Something went wrong`;
          }
          return `Network not available.`;
        }
      }
    } else {
      // const request = {
      //   Subject: "Frontend error on frontend/app.vrscheduler.com",
      //   Error: error || 'Something went wrong',
      //   Source: "FE",
      // };
      // console.log(request);
      // triggerStageEmail(request);
      if (
        !error.includes("timeout of 0ms exceeded") &&
        !error.includes("Request aborted")
      ) {
        errorEmail(error, 1);
      }
      return `Something went wrong`;
    }
  };

  render() {
    return <div>{this.ErrorDetails()}</div>;
  }
}
HandelError.propTypes = {
  error: PropTypes.objectOf(PropTypes.any).isRequired,
};
