import initialState from './InitialState';
import {SET_ALL_INTEGRATIONS_DETAILS, UPDATE_INTEGRATIONS_DETAILS, DISCONNECT_QUICKBOOKS} from '../actions/ActionTypes';

const IntegrationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_INTEGRATIONS_DETAILS: {
      const integrations = state.integrations ? state.integrations : [];
      return [...integrations, ...action.integrationsDetails];
    }
    case UPDATE_INTEGRATIONS_DETAILS: {
      return state.map((a) => (
        a.IntegrationID === action.data.IntegrationID ? {...a, Details: action.data} : a));
    }
    case DISCONNECT_QUICKBOOKS: {
      return state.map((a) => (
        a.IntegrationID === action.IntegrationID ? {...a, Installed: 0} : a));
    }
    default:
      return state;
  }
};
export default IntegrationsReducer;
