import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  ListItem,
  Button,
  makeStyles,
  Icon,
  List,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { getPermission } from "../../../utils/TokenManager";
import { VRSConst } from "../../../app/Constants";
import { MainHeaderMenu } from "./MainHeaderMenu";

const useStyles = makeStyles({
  padding0: {
    padding: "0 0 !important",
  },
  font15: {
    fontSize: "15px",
  },
  navbutton: {
    color: "#53677F",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    fontSize: "inherit",
    fontWeight: "inherit",
    textTransform: "inherit",
    border: "none",
    background: "none",
    padding: 0,
  },
  iconfont: {
    fontSize: "19px",
  },
  positionUnset: {
    position: "unset",
  },
});
let permission = getPermission("permission");
export const MainHeaderSubMenu = ({ SubNavList, NavList, handleNodeClick }) => {
  permission = getPermission("permission");
  const classes = useStyles();
  return (
    <Box className="nav-wrap">
      <List className="navigation">
        {SubNavList.length
          ? SubNavList.map((value) => {
              if (!value.isParent) {
                return (
                  <ListItem key={value.id} className={classes.padding0}>
                    <a href={value.path}>
                      {value.displayIcon ? (
                        <span className="ico-holder">
                          <Icon
                            className={`${value.displayIcon} ${classes.iconfont}`}
                          />
                        </span>
                      ) : (
                        ""
                      )}
                      <span className="text">{value.name}</span>
                    </a>
                  </ListItem>
                );
              }
              return (
                <ListItem
                  className={`drop-holder ${
                    !value.isSelected ? `` : `active-drop`
                  } ${classes.padding0} ${classes.positionUnset}`}
                >
                  <Button
                    className={`${classes.navbutton} drop-opener`}
                    onClick={() => handleNodeClick(value, "submenu")}
                  >
                    {value.displayIcon ? (
                      <span className="ico-holder">
                        <Icon
                          className={`${value.displayIcon} ${classes.iconfont}`}
                        />
                      </span>
                    ) : (
                      ""
                    )}
                    <span className="text">
                      {value.icon ? (
                        <span>
                          <Icon
                            className={`${value.icon} ${classes.iconfont}`}
                          />
                          <span className="text view-mobile">{value.name}</span>
                        </span>
                      ) : (
                        value.name
                      )}
                    </span>
                    <Box component="span" className="ico-holder">
                      {!value.isSelected ? (
                        <ExpandMoreIcon className={classes.font15} />
                      ) : (
                        <ExpandLessIcon className={classes.font15} />
                      )}
                    </Box>
                  </Button>
                  <Box
                    className={`dropdown ${
                      !value.isSelected ? `display-none` : ``
                    } `}
                  >
                    <Box className="drop-header">
                      <span className="title">{value.name}</span>
                      <Button
                        className={`${classes.navbutton} back`}
                        onClick={() => handleNodeClick(value, "submenu")}
                      >
                        <i className="fa fa-chevron-left" aria-hidden="true" />
                      </Button>
                    </Box>
                    <Box className="drop-body">
                      <List className="drop-list">
                        {value.name === "Filter" ? (
                          <span>
                            {permission &&
                            permission[VRSConst.module.ALLOW_REGION_GROUP] &&
                            permission.RegionGroupDetails ? (
                              <span>
                                <ListItem className={classes.padding0}>
                                  <a href="?frg=a">
                                    <i class="fa fa-check" />
                                    Show All Properties
                                  </a>
                                </ListItem>
                                <div
                                  className="nav-item"
                                  style={{ paddingTop: 10 }}
                                >
                                  -- Regions Groups --
                                </div>
                                {permission.RegionGroupDetails.map(
                                  (childValue) => (
                                    <ListItem className={classes.padding0}>
                                      <a
                                        href={`${VRSConst.menuRedirectedURL}/calendar?frg=${childValue.RegionGroupID}`}
                                      >
                                        {childValue.RegionGroup}
                                      </a>
                                    </ListItem>
                                  )
                                )}
                              </span>
                            ) : (
                              ""
                            )}
                            {permission &&
                            permission[VRSConst.module.ALLOW_PROPERTY_GROUP] &&
                            permission.PropertyGroupDetails ? (
                              <span>
                                <div className="nav-item">-- Tags --</div>
                                {permission.PropertyGroupDetails.map(
                                  (childValue) => (
                                    <ListItem className={classes.padding0}>
                                      <a
                                        href={`${VRSConst.menuRedirectedURL}/calendar?frg=${childValue.PropertyGroupID}`}
                                      >
                                        {childValue.PropertyGroup}
                                      </a>
                                    </ListItem>
                                  )
                                )}
                              </span>
                            ) : (
                              ""
                            )}
                          </span>
                        ) : value.childNode.length ? (
                          value.childNode.map((childNodeValue) => {
                            return (
                              <ListItem className={classes.padding0}>
                                <a href={childNodeValue.path}>
                                  {childNodeValue.name}
                                </a>
                              </ListItem>
                            );
                          })
                        ) : (
                          ""
                        )}
                      </List>
                    </Box>
                  </Box>
                </ListItem>
              );
            })
          : ""}
      </List>
      <MainHeaderMenu
        NavList={NavList}
        handleNodeClick={handleNodeClick}
        FromMain={0}
      />
    </Box>
  );
};
MainHeaderSubMenu.propTypes = {
  SubNavList: PropTypes.instanceOf(Array).isRequired,
  NavList: PropTypes.instanceOf(Array).isRequired,
  handleNodeClick: PropTypes.func.isRequired,
};
