import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { changeDateFormatLocale, changeTimeZone } from "../../../utils/Client";
import logo from "../../../assets/images/logo/updated_new_logo.svg";

export const MainHeaderInfo = () => {
  const userDetails = useSelector((state) => state.AuthReducer.user);
  return (
    <Box className="logo-holder">
      <Box className="logo">
        <Box component="img" src={logo} alt="V" width="32" height="24" />
      </Box>
      <Box className="date">
        <time
          dateTime={changeDateFormatLocale(
            changeTimeZone(new Date(), userDetails.region, "YYYY/MM/DD"),
            userDetails.LocaleID,
            "weekdaydt"
          )}
        >
          {userDetails
            ? changeDateFormatLocale(
                changeTimeZone(new Date(), userDetails.region, "YYYY/MM/DD"),
                userDetails.LocaleID,
                "weekdaydt"
              )
            : ""}
        </time>
      </Box>
    </Box>
  );
};
