import { VRSConst } from "../../app/Constants";
import { getLoggedInStaff, getPermission } from "../../utils/TokenManager";

const loggedInStaff = getLoggedInStaff();
let loggedInStaffId;
let loggedInServicerPwd;
if (loggedInStaff) {
  loggedInStaffId = loggedInStaff.loggedInStaffId;
  loggedInServicerPwd = loggedInStaff.loggedInServicerPwd;
}

const permissions = getPermission();

export const NavItem = [
  {
    name: "Mobile Dashboard",
    isParent: false,
    path: `http://vrsc.be/sd/?s=${loggedInStaffId}&u=${loggedInServicerPwd}`,
    module: loggedInStaffId ? VRSConst.module.ALLOW_MASTER : "",
    childNode: [],
  },
  {
    name: "More",
    isParent: true,
    path: `${VRSConst.menuRedirectedURL}/todo-property-servicer-view`,
    module: VRSConst.module.ALLOW_MAANGE,
    displayIcon: "fa fa-ellipsis-h",
    childNode: [
      {
        name: "Manage",
        isParent: false,
        path: `${VRSConst.menuRedirectedURL}/todo-property-servicer-view`,
        module: VRSConst.module.ALLOW_MAANGE,
        htmlTAG: "",
      },
      {
        name: "Stripe Owner Billing",
        isParent: false,
        path: `${VRSConst.menuRedirectedURL}/owner-bill`,
        module: VRSConst.module.ALLOW_CONNECTED_STRIPE_ID,
        htmlTAG: "",
      },
      {
        name: "Quickbooks",
        isParent: false,
        path: `${VRSConst.integrationURL}/`,
        module: VRSConst.module.ALLOW_USE_QUICKBOOKS,
        htmlTAG: "",
      },
      {
        name: "Time Tracking Report - QB",
        isParent: false,
        path: `${VRSConst.menuRedirectedURL}/report-time-tracking-all-quickbooks`,
        module: VRSConst.module.ALLOW_USE_QUICKBOOKS,
        htmlTAG: "",
      },
    ],
  },
  {
    name: "Reports",
    isParent: true,
    path: `${VRSConst.menuRedirectedURL}/properties`,
    module: VRSConst.module.ALLOW_REPORTS,
    displayIcon: "",
    childNode: [
      {
        name: "Task History",
        isParent: false,
        path: `${VRSConst.menuRedirectedURL}/report-service`,
        module: VRSConst.module.ALLOW_MASTER,
        htmlTAG: "",
      },
      {
        name: "Issue History",
        isParent: false,
        path: `${VRSConst.menuRedirectedURL}/report-issues`,
        module: VRSConst.module.ALLOW_MASTER,
        htmlTAG: "",
      },
      {
        name: "Task Forms Report (Checklists)",
        isParent: false,
        path: `${VRSConst.menuRedirectedURL}/report-taskforms`,
        module: VRSConst.module.ALLOW_MASTER,
        htmlTAG: "",
      },
      {
        name: "Time Tracking Tasks",
        isParent: false,
        path: `${VRSConst.menuRedirectedURL}/report-time-tracking-tasks`,
        module: VRSConst.module.ALLOW_TIME_TRACKING,
        htmlTAG: "",
      },
      {
        name: "Time Tracking Employees",
        isParent: false,
        path: `${VRSConst.menuRedirectedURL}/report-time-tracking-days`,
        module: VRSConst.module.ALLOW_TIME_TRACKING,
        htmlTAG: "",
      },
      {
        name: "Time Tracking All",
        isParent: false,
        path: `${VRSConst.menuRedirectedURL}/report-time-tracking-all`,
        module: VRSConst.module.ALLOW_TIME_TRACKING,
        htmlTAG: "",
      },
      {
        name: "Time Tracking Details",
        isParent: false,
        path: `${VRSConst.menuRedirectedURL}/report-time-tracking-all-details`,
        module: VRSConst.module.ALLOW_TIME_TRACKING,
        htmlTAG: "",
      },
      {
        name: "Piece Pay",
        isParent: false,
        path: `${VRSConst.menuRedirectedURL}/report-piece-pay`,
        module: VRSConst.module.ALLOW_PIECE_PAY,
        htmlTAG: "",
      },
      {
        name: "Blended Pay",
        isParent: false,
        path: `${VRSConst.menuRedirectedURL}/report-pay-review`,
        module: VRSConst.module.ALLOW_MASTER,
        htmlTAG: "",
      },
      {
        name: "Owner Statement Report",
        isParent: false,
        path: `${VRSConst.menuRedirectedURL}/report-owner-statement`,
        module:
          VRSConst.module.ALLOW_TRACK_LABOR_MATERIAL &&
          VRSConst.module.ALLOW_INTEGRATION_COMPANY_ID,
        htmlTAG: "",
      },
      {
        name: "Accept / Decline Report",
        isParent: false,
        path: `${VRSConst.menuRedirectedURL}/report-accept-decline`,
        module: VRSConst.module.ALLOW_MASTER,
        htmlTAG: "",
      },
      {
        name: "Booking History",
        isParent: false,
        path: `${VRSConst.menuRedirectedURL}/report-bookings`,
        module: VRSConst.module.ALLOW_MASTER,
        htmlTAG: "",
      },
      {
        name: "Guest History",
        isParent: false,
        path: `${VRSConst.menuRedirectedURL}/report-guest`,
        module: VRSConst.module.ALLOW_MASTER,
        htmlTAG: "",
      },
      {
        name: "Notification History",
        isParent: false,
        path: `${VRSConst.menuRedirectedURL}/report-notifications`,
        module: VRSConst.module.ALLOW_MASTER,
        htmlTAG: "",
      },
    ],
  },
  {
    name: "Setup",
    isParent: true,
    path: `${VRSConst.menuRedirectedURL}/properties`,
    module: VRSConst.module.ALLOW_SETUP_ACCESS,
    displayIcon: "fa fa-cog",
    childNode: [
      {
        name: "Staff and Owner Notifications",
        isParent: false,
        path: `${VRSConst.menuRedirectedURL}/notifications-alerts`,
        module: VRSConst.module.ALLOW_MASTER,
        htmlTAG: "",
      },
      {
        name: "Administrator Notifications",
        isParent: false,
        path: `${VRSConst.menuRedirectedURL}/notifications-alerts-employees`,
        module: VRSConst.module.ALLOW_MASTER,
        htmlTAG: "",
      },
      {
        name: "Notification Rules",
        isParent: false,
        path: `${VRSConst.menuRedirectedURL}/notification-rules`,
        module: VRSConst.module.ALLOW_MASTER,
        htmlTAG: "<hr />",
      },
      {
        name: "Task Rules",
        isParent: false,
        path: `${VRSConst.menuRedirectedURL}/services`,
        module: VRSConst.module.ALLOW_MASTER,
        htmlTAG: "",
      },
      {
        name: "Task Forms(Checklists)",
        isParent: false,
        path: `${VRSConst.menuRedirectedURL}/checklists`,
        module: VRSConst.module.ALLOW_MASTER,
        htmlTAG: "<hr />",
      },
      {
        name: "Staff",
        isParent: false,
        path: `${VRSConst.menuRedirectedURL}/servicers`,
        module: VRSConst.module.ALLOW_MASTER,
        htmlTAG: "",
      },
      {
        name: "Properties",
        isParent: false,
        path: `${VRSConst.menuRedirectedURL}/properties`,
        module: VRSConst.module.ALLOW_MASTER,
        htmlTAG: "",
      },
      {
        name: "Regions",
        isParent: false,
        path: `${VRSConst.menuRedirectedURL}/regions`,
        module: VRSConst.module.ALLOW_MASTER,
        htmlTAG: "",
      },
      {
        name: "Owners",
        isParent: false,
        path: `${VRSConst.menuRedirectedURL}/owners`,
        module: VRSConst.module.ALLOW_MASTER,
        htmlTAG: "",
      },
      {
        name: "Vendors",
        isParent: false,
        path: `${VRSConst.menuRedirectedURL}/vendors`,
        module: VRSConst.module.ALLOW_MASTER,
        htmlTAG: "<hr />",
      },
      {
        name: "Property Statuses",
        isParent: false,
        path: `${VRSConst.menuRedirectedURL}/property-statuses`,
        module: VRSConst.module.ALLOW_MASTER,
        htmlTAG: "",
      },
      {
        name: "Departments",
        isParent: false,
        path: `${VRSConst.menuRedirectedURL}/service-groups`,
        module: VRSConst.module.ALLOW_MASTER,
        htmlTAG: "",
      },
      {
        name: "Staff Tags",
        isParent: false,
        path: `${VRSConst.menuRedirectedURL}/employee-groups`,
        module: VRSConst.module.ALLOW_MASTER,
        htmlTAG: "",
      },
      {
        name: "Property Tags",
        isParent: false,
        path: `${VRSConst.menuRedirectedURL}/property-groups`,
        module: VRSConst.module.ALLOW_MASTER,
        htmlTAG: "<hr />",
      },
      {
        name: "System Settings",
        isParent: false,
        path: `${VRSConst.menuRedirectedURL}/settings`,
        module: VRSConst.module.ALLOW_MASTER,
        htmlTAG: "",
      },
      {
        name: "iCal Feeds",
        isParent: false,
        path: `${VRSConst.menuRedirectedURL}/feeds/feeds`,
        module: VRSConst.module.ALLOW_I_CAL_ADD_ON,
        htmlTAG: "",
      },
      ...(permissions && permissions[VRSConst.module.ALLOW_HAS_PUBLIC_API_ACCESS]
        ? [
            {
              name: "API Keys",
              isParent: false,
              path: `${VRSConst.menuRedirectedURL}/api-keys`,
              module: VRSConst.module.ALLOW_HAS_PUBLIC_API_ACCESS,
              htmlTAG: "",
            },
          ]
        : []),
      {
        name: "Onboarding Steps",
        isParent: false,
        path: `${VRSConst.menuRedirectedURL}/onboarding`,
        module: VRSConst.module.ALLOW_MASTER,
        htmlTAG: "",
      },
    ],
  },
  {
    name: "Account",
    isParent: false,
    isUserPart: true,
    path: `${VRSConst.menuRedirectedURL}/account`,
    childNode: [],
    module: VRSConst.module.ALLOW_ACCOUNT_ACCESS,
  },
  {
    name: "Help & Feedback",
    isParent: false,
    path: `${VRSConst.menuHelpRedurectedURL}`,
    displayIcon: "fa fa-question-circle",
    childNode: [],
    module: VRSConst.module.ALLOW_MASTER,
  },
  {
    name: "Logout",
    isParent: false,
    isUserPart: true,
    path: `${VRSConst.menuRedirectedURL}/logout`,
    childNode: [],
    module: VRSConst.module.ALLOW_MASTER,
  },
];
