import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Button } from "@material-ui/core";
import { MainHeaderInfo } from "./MainHeaderInfo";
import { MainHeaderSubMenu } from "./MainHeaderSubMenu";
import { MainHeaderMenu } from "./MainHeaderMenu";
import "../../../assets/css/newHeader.css";

const NewMainHeaderContainer = ({ NavList, SubNavList, handleNodeClick }) => {
  const [navActive, setNavActive] = useState(0);
  return (
    <Box id="wrapper" className={navActive ? `nav-active` : ``}>
      <header id="header" className="nav-area-active">
        <Box className="container">
          <MainHeaderInfo />
          <Box className="nav-opener-holder">
            <Button
              className="nav-opener"
              onClick={() => setNavActive(!navActive)}
            >
              {navActive ? (
                <i className="fa fa-times" aria-hidden="true" />
              ) : (
                <i className="fa fa-bars" aria-hidden="true" />
              )}
            </Button>
          </Box>
          <Box className="nav-drop">
            <MainHeaderSubMenu
              SubNavList={SubNavList}
              NavList={NavList}
              handleNodeClick={handleNodeClick}
            />
          </Box>
          <MainHeaderMenu
            NavList={NavList}
            handleNodeClick={handleNodeClick}
            FromMain={1}
          />
        </Box>
      </header>
    </Box>
  );
};

export default NewMainHeaderContainer;

NewMainHeaderContainer.propTypes = {
  NavList: PropTypes.instanceOf(Array).isRequired,
  SubNavList: PropTypes.instanceOf(Array).isRequired,
  handleNodeClick: PropTypes.func.isRequired,
};
