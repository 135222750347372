import initialState from './InitialState';
import {SET_ALL_ITEMS_DETAILS, SET_ALL_TASK_RULE_DETAILS} from '../actions/ActionTypes';

const MapTaskRuleReducer = (state = initialState.mapTaskRules, action) => {
  switch (action.type) {
    case SET_ALL_TASK_RULE_DETAILS: {
      return {
        ...state,
        taskRule: action.tasksDetails,
      };
    }
    case SET_ALL_ITEMS_DETAILS: {
      return {
        ...state,
        items: action.itemsDetails,
      };
    }
    default:
      return state;
  }
};
export default MapTaskRuleReducer;
