import PropTypes from "prop-types";
import { React, Component } from "react";
import { toast } from "react-toastify";
import StackTrace from "stacktrace-js";
import { VRSConst } from "../../app/Constants";
import MobileDashboardService from "../../services/MobileDashboardService";
import { getFromLocalStorage } from "../../utils/Helper";
import { isOnline } from "../../utils/offlineUtils";
import { HandelError } from "./HandleError";

export default class ErrorBoundary extends Component {
  state = {
    error: "",
    errorInfo: "",
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    try {
      StackTrace.fromError(error)
        .then((err) => {
          const request = {
            Subject: `Frontend error on ${window.location.href}`,
            Error: `ErrorDetails - ${error} \n
            User: ${getFromLocalStorage("MobileDashBoardCred")} \n
            User error: 'Error page' \n
            userAgent: ${window.navigator.userAgent} \n
            appVersion: ${VRSConst.reactAppVersion} \n
            Offline: ${!isOnline()} \n
            ErrorBoundry- ${err[0]}`,
            Source: "FE",
          };
          if (
            !error
              .toString()
              .includes("TypeError: undefined is not a function") &&
            !error
              .toString()
              .includes("SecurityError: The operation is insecure") &&
            error.message !== "SecurityError" &&
            error.message !== "The operation is insecure"
          ) {
            MobileDashboardService.triggerEmail(request);
          }
        })
        .catch((err2) => {
          console.log(err2);
        });
      this.setState({ errorInfo, hasError: true });
    } catch (e) {
      console.log(e, "catch error");
    }
    // eslint-disable-next-line no-console
  }

  removeLoader = () => {
    const loader = document.getElementById("parentLoader");
    if (loader) {
      loader.remove();
    }
  };

  handleRefreshForUpdate = () => {
    if (
      "serviceWorker" in navigator &&
      navigator.serviceWorker.getRegistration
    ) {
      navigator.serviceWorker
        .getRegistration()
        .then((reg) => {
          if (reg && reg.waiting) {
            reg.waiting.postMessage("skipWaiting");
          }
          window.location.reload();
        })
        .catch((err) => {
          toast.error(<HandelError error={err} />, { autoClose: 2000 });
          window.location.reload();
        });
    } else {
      window.location.reload();
    }
  };

  render() {
    // next code block goes here
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <table
          style={{ maxWidth: "600px" }}
          border="0"
          cellSpacing="0"
          cellPadding="0"
          bgcolor="#FFFFFF"
          align="center"
        >
          <tbody>
            <tr>
              <td align="center">
                {this.removeLoader()}
                <br />
                <br />
                <h1>
                  Operto Teams
                  <br />
                  <br />
                  AN ERROR HAS OCCURRED
                </h1>
                <br />
                <br />
                <br />
              </td>
            </tr>
            <tr>
              <td
                bgcolor="FFFFFF"
                valign="top"
                className="bodytext"
                align="center"
              >
                We are so sorry and thank you for your patience.
                <br />
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => {
                    this.handleRefreshForUpdate();
                  }}
                  style={{ textAlign: "left" }}
                >
                  Please click here to return to your employee dashboard.
                </button>
                <br />
              </td>
            </tr>
          </tbody>
        </table>
      );
    }
    return children;
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};
