import IntegrationService from "../../services/IntegrationService";
import {setAllIntegrations, updateIntegrationDetails, disconnectQBAction} from "../actions/IntegrationsAction";

export const getIntegrations = async (dispatch) => {
  try {
    const allIntegrationResponse = await IntegrationService.getAllIntegrations();
    if (allIntegrationResponse.data.ReasonCode === 0) {
      dispatch(setAllIntegrations(allIntegrationResponse.data.IntegrationDetailsResponse));
      return true;
    }
  } catch (e) {
    throw e.response.data;
  }
  return false;
};

/**
 * service call to install the integrations
 * @param requestData
 * @returns {Promise<boolean>}
 */
export const installIntegrations = async (requestData) => {
  try {
    const installIntegrationsResponse = await IntegrationService.installIntegrations(requestData);
    if (installIntegrationsResponse.data.ReasonCode === 0) {
      return true;
    }
  } catch (e) {
    throw e.response.data;
  }
  return false;
};

/**
 * service call to change integrations details
 * @param requestData
 * @param dispatch
 * @returns {Promise<boolean>}
 */
export const changeIntegrations = async (requestData, dispatch) => {
  try {
    const IntegrationsResponse = await IntegrationService.changeIntegrationDetails(requestData);
    if (IntegrationsResponse.data.ReasonCode === 0) {
      dispatch(updateIntegrationDetails(requestData));
      return true;
    }
  } catch (e) {
    throw e.response.data;
  }
  return false;
};
/**
 * Service call to download the zip file
 * @param requestData
 * @returns {Promise<boolean>}
 */
export const downloadIntegrations = async (requestData) => {
  try {
    const downloadIntegrationsResponse = await IntegrationService.downloadIntegrations(requestData);
    const url = window.URL.createObjectURL(new Blob([downloadIntegrationsResponse.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'WebConnectFiles.zip');
    link.click();
    return true;
  } catch (e) {
    throw e.response.data;
  }
};
/**
 * update integration
 * @param requestData
 * @param dispatch
 * @returns {Promise<boolean>}
 */
export const disconnect = async (requestData, dispatch) => {
  try {
    const authResponse = await IntegrationService.disconnectQB(requestData);
    if (authResponse.data.ReasonCode === 0) {
      dispatch(disconnectQBAction(requestData));
    }
    return true;
  } catch (error) {
    throw error.response.data;
  }
};

/**
 * call service to get the connect to QB url and return it to component
 * @returns {Promise<boolean|*>}
 */
export const connectQBOnline = async () => {
  try {
    const res = await IntegrationService.connectQBOnline();
    if (res.data.ReasonCode === 0) {
      window.open(res.data.RedirectURI, "_self");
      return true;
      // dispatch(disconnectQBAction(requestData));
    }
    return true;
  } catch (error) {
    throw error.response.data;
  }
};

/**
 * Call service to sync resources to system and returns the result to component
 * @returns {Promise<boolean|T>}
 */
export const syncResources = async (integrationID) => {
  try {
    const res = await IntegrationService.QBOSyncResources(integrationID);
    return res.data.ReasonCode === 0;
  } catch (error) {
    throw error.response.data;
  }
};

/**
 * Call service to sync data to quickbooks and send the response back too component
 * @param integrationID
 * @returns {Promise<boolean>}
 */
export const syncDataToQB = async (integrationID) => {
  try {
    const res = await IntegrationService.syncToQBO(integrationID);
    return res.data.ReasonCode === 0;
  } catch (error) {
    throw error.response.data;
  }
};

/**
 * Call service to sync data to quickbooks and send the response back too component
 * @param integrationID
 * @returns {Promise<boolean>}
 */
export const syncTimeTrackingDataToQB = async (integrationID) => {
  try {
    const res = await IntegrationService.syncTimeTrackingToQBO(integrationID);
    return res.data.ReasonCode === 0;
  } catch (error) {
    throw error.response.data;
  }
};

export const installZapierIntegration = async (requestData) => {
  try {
    const res = await IntegrationService.installZapierService(requestData);
    return res.data.ReasonCode === 0;
  } catch (error) {
    throw error.response.data;
  }
}