import {convertJsonBase64, mobileAxiosInstance} from "../utils/Client";
import {getToken} from "../utils/TokenManager";
import {constructRequest} from "../utils/offlineUtils";
import {getFromLocalStorage} from "../utils/Helper";
import {VRSConst} from "../app/Constants";

let Status = true;

window.addEventListener('online', () => {
  Status = true;
});
window.addEventListener('offline', () => {
  Status = false;
});

export default class MobileDashboardService {
  static getAllTasks = async (token, offset) => {
    let Response = null;
    if (offset > 1) {
      const offsetValue = {Offset: offset};
      Response = await mobileAxiosInstance.get(`/pwa/tasks?data=${convertJsonBase64(offsetValue)}`, {headers: {Authorization: `VRS ${token}`, Offline: !Status, Version: VRSConst.reactAppVersion}});
    } else {
      Response = await mobileAxiosInstance.get(`/pwa/tasks`, {headers: {Authorization: `VRS ${token}`, Offline: !Status, Version: VRSConst.reactAppVersion}});
    }

    return Response;
  };

  static starTask = async (requestData) => {
    let Response = null;
    constructRequest('/pwa/starttask', requestData);
    Response = await mobileAxiosInstance.post(`/pwa/starttask`, requestData, {headers: {Authorization: `VRS ${getToken('MJWT')}`, Offline: !Status, Version: VRSConst.reactAppVersion}});
    return Response;
  };

  static clockInOut = async (requestData) => {
    let Response = null;
    constructRequest('/pwa/clockinout', requestData);
    Response = await mobileAxiosInstance.post(`/pwa/clockinout`, requestData, {headers: {Authorization: `VRS ${getToken('MJWT')}`, Offline: !Status, Version: VRSConst.reactAppVersion}});
    return Response;
  };

  static acceptDecline = async (requestData) => {
    let Response = null;
    console.log("accept decline service called");
    constructRequest('/pwa/task/acceptdecline', requestData);
    Response = await mobileAxiosInstance.post(`/pwa/task/acceptdecline`, requestData, {headers: {Authorization: `VRS ${getToken('MJWT')}`, Offline: !Status, Version: VRSConst.reactAppVersion}});
    return Response;
  };

  static changeStartDate = async (requestData) => {
    let Response = null;
    constructRequest('/pwa/task/changedate', requestData);
    Response = await mobileAxiosInstance.post(`/pwa/task/changedate`, requestData, {headers: {Authorization: `VRS ${getToken('MJWT')}`, Offline: !Status, Version: VRSConst.reactAppVersion}});
    return Response;
  };



  static triggerEmailWithMessage = async (errorMessage) => {
    const requestData = {
      Subject: `Frontend error on ${window.location.href}`,
      Error: `ErrorDetails - ${errorMessage} \n
            User: ${getFromLocalStorage('MobileDashBoardCred')} \n
            User error: 'Error page' \n
            userAgent: ${window.navigator.userAgent} \n
            appVersion: ${VRSConst.reactAppVersion} \n
            Offline: ${!navigator.onLine} \n
            ErrorBoundry- `,
      Source: "FE",
    };
    let Response = null;
    Response = await mobileAxiosInstance.post(`/pwa/email`, requestData, {headers: {Authorization: `VRS ${getToken('MJWT')}`, Offline: !Status, Version: VRSConst.reactAppVersion}});
    // Response = await axios.post(`https://api.vrscheduler.com/v1/vrs/pwa/email`, request, {headers: {Authorization: `VRS ${getToken('MJWT')}`}});
    return Response;

  }

  static log = async (logmessage) => {
    const requestData = {
      Log: logmessage,
      User: `${getFromLocalStorage('MobileDashBoardCred')}`
    };
    let Response = null;
    Response = await mobileAxiosInstance.post(`/pwa/logfe`, requestData, {headers: {Authorization: `VRS ${getToken('MJWT')}`, Offline: !Status, Version: VRSConst.reactAppVersion}});
    return Response;

  }

  static triggerEmail = async (requestData) => {
    let Response = null;
    Response = await mobileAxiosInstance.post(`/pwa/email`, requestData, {headers: {Authorization: `VRS ${getToken('MJWT')}`, Offline: !Status, Version: VRSConst.reactAppVersion}});
    // Response = await axios.post(`https://api.vrscheduler.com/v1/vrs/pwa/email`, requestData, {headers: {Authorization: `VRS ${getToken('MJWT')}`}});
    return Response;
  }
}
