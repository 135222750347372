import initialState from './InitialState';
import {SET_ALL_PROPERTIES_DETAILS, SET_ALL_CUSTOMERS_DETAILS} from '../actions/ActionTypes';

const MapPropertiesReducer = (state = initialState.mapProperties, action) => {
  switch (action.type) {
    case SET_ALL_PROPERTIES_DETAILS: {
      return {
        ...state,
        properties: action.propertiesDetails,
      };
    }
    case SET_ALL_CUSTOMERS_DETAILS: {
      return {
        ...state,
        customers: action.customersDetails,
      };
    }
    default:
      return state;
  }
};
export default MapPropertiesReducer;
