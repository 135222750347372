import React from "react";
import Header from "./Header";
import { subMenuItem } from "./submenu/List";
import {NavItem} from "./NavItem";

const HeaderList = ({permission, userDetails}) => {
  return (
    <Header NavItem={NavItem} SubNavItem={subMenuItem(permission)} userDetails={userDetails}/>
  );
};
export default HeaderList;
