import initialState from "./InitialState";
import {
  ACCEPT_DECLINE_TASK,
  CHANGE_DATE,
  COMPLETE_TASK,
  SET_MOBILE_TASKS,
  START_TASK,
  DOWNLOAD_TASK,
} from "../actions/ActionTypes";

const MobileDashboardReducer = (
  state = initialState.mobileDashBoard,
  action
) => {
  switch (action.type) {
    case SET_MOBILE_TASKS: {
      let { tasks, notes } = state;
      tasks = [...action.tasks.Tasks];
      notes = [...notes, ...action.tasks.Notes];
      const prevTask = tasks.slice(0, 5).map((task1) => ({
        ...task1,
        download: true,
      }));
      const nextTask = tasks.slice(5, action.tasks.length).map((task1) => ({
        ...task1,
        download: false,
      }));
      const task = [...prevTask, ...nextTask];
      const nontask = action.tasks.Tasks.filter((value) => value.IsTask === 0);
      const hastask = action.tasks.Tasks.filter((value) => value.IsTask === 1);
      const more =
        hastask.length >= 31 && nontask.length !== action.tasks.Tasks.length;
      return {
        ...state,
        tasks: task,
        notes,
        isMore: more,
      };
    }
    case DOWNLOAD_TASK: {
      const stateData = { ...state };
      const data = [...stateData.tasks];
      const finalTask = data.map((singletask) => {
        if (parseInt(singletask.IsTask, 10) && singletask.Details) {
          if (singletask.Details.TaskID === action.task.TaskID) {
            return {
              ...singletask,
              download: true,
            };
          }
        }
        return singletask;
      });
      return {
        ...state,
        tasks: finalTask,
      };
    }
    case START_TASK: {
      const tasks = state.tasks.map((Task) => {
        if (parseInt(Task.IsTask, 10) && Task.Details) {
          if (Task.Details.TaskID === action.task.TaskID) {
            // Task.ClockedIn = action.startTask;
            return {
              ...Task,
              Details: {
                ...Task.Details,
                Started: action.manageTask ? action.startTask : "",
              },
              StartTask: action.manageTask ? 0 : 1,
              InfoChecklistPreview: action.manageTask ? 0 : 1,
              PauseTask: action.manageTask,
              Tabs: {
                ...Task.Tabs,
                Manage: action.manageTask,
              },
            };
          }
          return {
            ...Task,
            StartTask: Task.PauseTask ? 1 : Task.StartTask,
            PauseTask: 0,
            Details: {
              ...Task.Details,
              Started: "",
            },
            Tabs: {
              ...Task.Tabs,
              Manage: 0,
            },
          };
        }
        return Task;
      });

      return {
        ...state,
        tasks,
      };
    }
    case ACCEPT_DECLINE_TASK: {
      let tasks = [];
      if (action.manageTask) {
        tasks = state.tasks.map((Task) => {
          if (parseInt(Task.IsTask, 10) && Task.Details) {
            if (Task.Details.TaskID === action.task.TaskID) {
              // Task.ClockedIn = action.startTask;
              return {
                ...Task,
                AcceptDecline: 0,
              };
            }
          }
          return Task;
        });
      } else {
        tasks = state.tasks.filter((Task) =>
          parseInt(Task.IsTask, 10)
            ? Task.Details.TaskID !== action.task.TaskID
            : Task
        );
      }
      return {
        ...state,
        tasks,
      };
    }
    case CHANGE_DATE: {
      const tasks = state.tasks.map((Task) => {
        if (parseInt(Task.IsTask, 10) && Task.Details) {
          if (Task.Details.TaskID === action.task.TaskID) {
            // Task.ClockedIn = action.startTask;
            return {
              ...Task,
              AssignedDate: action.task.TaskDate,
              Details: {
                ...Task.Details,
                SchedulingNote: action.task.SchedulingNote,
              },
            };
          }
        }
        return Task;
      });
      // console.log(state, 'reduceer');
      return {
        ...state,
        tasks,
      };
    }
    case COMPLETE_TASK: {
      const tasks = state.tasks.filter((Task) =>
        parseInt(Task.IsTask, 10)
          ? Task.Details.TaskID !== action.task.TaskID
          : Task
      );
      return {
        ...state,
        tasks,
      };
    }
    default:
      return state;
  }
};
export default MobileDashboardReducer;
