import React, {Suspense} from 'react';
import {Route, BrowserRouter, Switch} from 'react-router-dom';
import * as RoutePage from './index';
import RouteList from "./RouteList";
import {Loader} from "../../component/common/Loader";

const loading = () => (
  <Loader width={7} isLoading />
);

export const AppRoute = () => (
  <BrowserRouter>
    <Suspense fallback={loading()}>
      <Switch>
        <Route
          path={RouteList.mobileApp.path}
          render={(props) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <RouteList.mobileApp.component {...props} routes={RouteList.mobileApp.mobileRoutes} />
          )}
        />
        <Route
          path={RouteList.translationApp.path}
          render={(props) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <RouteList.translationApp.component {...props} routes={RouteList.translationApp.routes} />
          )}
        />
        <Route
          path={RouteList.issueApp.path}
          render={(props) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <RouteList.issueApp.component {...props} />
          )}
        />
        <RoutePage.App>
          {
            RouteList.routes.map((value) => (
              <Route
                key={value.path}
                exact
                path={value.path}
                render={(props) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <value.component {...props} />
                )}
              />
            ))
          }
        </RoutePage.App>
      </Switch>
    </Suspense>
  </BrowserRouter>
);
