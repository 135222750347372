import React from "react";
import { VRSConst } from "../../app/Constants";

export const Footer = () => (
  <footer>
    <p>
      <a
        href={`${VRSConst.menuRedirectedURL}/updates`}
        style={{ color: "#fff" }}
      >
        {" "}
        Updates{" "}
      </a>
      |
      <a
        href={`${VRSConst.menuRedirectedURL}/terms-conditions`}
        style={{ color: "#fff" }}
      >
        {" "}
        Terms and Conditions{" "}
      </a>
      |
      <a
        href={`${VRSConst.menuRedirectedURL}/contact-info`}
        style={{ color: "#fff" }}
      >
        {" "}
        Contact{" "}
      </a>
      |
      <a
        href={`${VRSConst.menuRedirectedURL}/industry-partners`}
        style={{ color: "#fff" }}
      >
        {" "}
        Industry Links{" "}
      </a>
      | Copyright © teams.operto.com {new Date().getFullYear()}
    </p>
  </footer>
);
