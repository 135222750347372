import {combineReducers} from 'redux';
import AuthReducer from "./AuthReducer";
import IntegrationsReducer from "./IntegrationsReducer";
import MapPropertiesReducer from "./MapPropertiesReducer";
import MapPayrollReducer from "./MapPayrollReducer";
import MapStaffsReducer from "./MapStaffsReducer";
import MapTaskRuleReducer from "./MapTaskRulesReducer";
import FiltersReducer from "./FiltersReducer";
import BillingApprove from "./BillingApproveReducer";
import TimeTrackingReducer from "./TimeTrackingReducer";
import SyncLogsReducer from "./SyncLogsReducer";
import TranslationsReducer from "./TranslationsReducer";
import ZapierReducer from "./ZapierReducer";

/*mobile dashboard */
import MobileDashboardReducer from "./MobileDashboardReducer";
import MobileDasboardTabsReducer from "./MobileDasboardTabsReducer";
import UnscheduledTaskReducer from "./UnscheduledTaskReducer";

const rootReducer = combineReducers({
  AuthReducer,
  IntegrationsReducer,
  MapPropertiesReducer,
  MapPayrollReducer,
  MapStaffsReducer,
  FiltersReducer,
  MapTaskRuleReducer,
  BillingApprove,
  TimeTrackingReducer,
  SyncLogsReducer,
  MobileDashboardReducer,
  MobileDasboardTabsReducer,
  UnscheduledTaskReducer,
  TranslationsReducer,
  ZapierReducer,
});

export default rootReducer;
