import initialState from './InitialState';
import {SET_QBD_WAGES, SET_PAY_ROLL} from '../actions/ActionTypes';

const MapPayrollReducer = (state = initialState.mapPayroll, action) => {
  switch (action.type) {
    case SET_QBD_WAGES: {
      return {
        ...state,
        wages: action.wages,
      };
    }
    case SET_PAY_ROLL: {
      const payroll = action.payroll ? action.payroll : {};
      return {
        ...state,
        payroll,
      };
    }
    default:
      return state;
  }
};
export default MapPayrollReducer;
