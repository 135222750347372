import axios from 'axios';
import {isEmpty} from "lodash";
import {VRSConst} from "../app/Constants";
import {getToken} from "./TokenManager";
import idbKeyval from "./idbUtlis";
import {dataURLToBlob, handleToaster} from "./Helper";

const isOnline = () => navigator.onLine;

// fetch construct
async function postData(data) {
  // Default options are marked with *
  // console.log(data, 'here');
  if (data.headers && data.headers.isImg) {
    const formData = new FormData();
    formData.append('Image', data.body);
    formData.append('ImageName', data.headers.ImageName);
    formData.append('CustomerID', data.headers.CustomerID);
    const response = await axios({
      method: data.method,
      url: data.url,
      data: formData,
      headers: {
        Authorization: `VRS ${getToken('MJWT')}`,
        Offline: true,
        "Content-Type": "multipart/form-data",
      },
    });
    // const response = await fetch(data.url, {
    //   method: data.method, // *GET, POST, PUT, DELETE, etc.
    //   mode: 'cors', // no-cors, *cors, same-origin
    //   cache: "default", // *default, no-cache, reload, force-cache, only-if-cached
    //   credentials: 'same-origin', // include, *same-origin, omit
    //   headers: {
    //     Authorization: `VRS ${getToken('MJWT')}`,
    //     Offline: true,
    //     'content-type': 'multipart/form-data'
    //   },
    //   redirect: 'follow', // manual, *follow, error
    //   referrerPolicy: "no-referrer-when-downgrade", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    //   body: formData, // body data type must match "Content-Type" header
    // });
    return response.data;
  }
  const response = await fetch(data.url, {
    method: data.method, // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: "default", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: data.headers,
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: "no-referrer-when-downgrade", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: data.body, // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}
const fetchFromIDB = async () => {
  const keyArray = await idbKeyval.keys();
  if (keyArray.length) {
    /* eslint-disable no-await-in-loop */
    for (let i = 0; i < keyArray.length; i += 1) {
      const key = keyArray[i];
      // console.log(key, 'inside forach1 ');
      const data = await idbKeyval.get(key);
      if ((!isEmpty(data) && !isEmpty(data.body) && data.body) || (data.headers && data.headers.isImg)) {
        try {
          const res = await postData(data);
          if (res) {
            await idbKeyval.delete(key);
          }
        } catch (e) {
          console.log(e);
          throw e;
        }
      }
    }
  }
};
const constructRequest = (url, requestData, Method = 'POST', isImg = 0) => {
  if (!isOnline()) {
    if (isImg) {
      const reqDataFake = {
        url: `${VRSConst.getUrl()}${url}`,
        method: Method,
        referrerPolicy: "no-referrer-when-downgrade",
        mode: "cors",
        credentials: "same-origin",
        cache: "default",
        redirect: "follow",
        integrity: "",
        keepalive: false,
        headers: {
          Authorization: `VRS ${getToken('MJWT')}`,
          Offline: true,
          'content-type': 'multipart/form-data',
          ImageName: requestData.ImageName,
          CustomerID: requestData.CustomerID,
          isImg,
        },
        body: dataURLToBlob(requestData.Image),
      };
      idbKeyval.set(reqDataFake);
    } else {
      const reqData = {
        url: `${VRSConst.getUrl()}${url}`,
        method: Method,
        referrerPolicy: "no-referrer-when-downgrade",
        mode: "cors",
        credentials: "same-origin",
        cache: "default",
        redirect: "follow",
        integrity: "",
        keepalive: false,
        headers: {
          Authorization: `VRS ${getToken('MJWT')}`,
          Offline: true,
          'content-type': 'application/json',
        },
        body: JSON.stringify(requestData),
      };
      idbKeyval.set(reqData);
    }
  }
};
const pdfReader = (url, newTab = 0) => {
  if (isOnline()) {
    window.open(url, `${newTab ? `_blank` : ''}`);
  } else {
    handleToaster("This functionality is not available when offline", 'I');
  }
};
export {
  isOnline,
  constructRequest,
  fetchFromIDB,
  pdfReader,
};
