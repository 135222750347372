import * as FullStory from "@fullstory/browser";
import PropTypes from "prop-types";
import React from "react";
import { Provider } from "react-redux";
import { VRSConst } from "./app/Constants";
import { ConfigureStore } from "./redux/store/ConfigureStore";
const store = ConfigureStore();


if (VRSConst.isFullStoryEnabled) {
  FullStory.init({ orgId: VRSConst.fullStoryOrgId });
}

const Root = ({ children }) => <Provider store={store}>{children}</Provider>;

Root.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.object.isRequired,
};

export default Root;
