import {isEmpty} from 'lodash';
import { VRSConst } from "../../../app/Constants";
import {getLoggedInStaff} from "../../../utils/TokenManager";
import { get2DaysBeforeDate } from "../../../utils/Client";

const loggedInStaff = Object.assign({}, getLoggedInStaff());
// const permission = Object.assign({}, getPermission());

export const subMenuItem = (permission) => {
  const SubMenu = [
    {
      icon: '',
      name: 'Issues',
      isParent: false,
      path: `${VRSConst.menuRedirectedURL}/issues`,
      module: VRSConst.module.ALLOW_ISSUE_ACCESS,
      childNode: [],
      displayIcon: '',
    },
    {
      icon: '',
      name: 'Quick Reports',
      isParent: true,
      path: '#',
      module: VRSConst.module.ALLOW_QUICK_REPORTS,
      displayIcon: '',
      childNode: [
        {
          icon: '',
          name: 'Arrivals',
          isParent: false,
          path: `${VRSConst.menuRedirectedURL}/arrivals`,
          module: VRSConst.module.ALLOW_MASTER,
        },
        {
          icon: '',
          name: 'Departures',
          isParent: false,
          path: `${VRSConst.menuRedirectedURL}/departures`,
          module: VRSConst.module.ALLOW_MASTER,
        },
        {
          icon: '',
          name: 'Check In/Outs',
          isParent: false,
          path: `${VRSConst.menuRedirectedURL}/inouts`,
          module: VRSConst.module.ALLOW_MASTER,
        },
        {
          icon: '',
          name: 'Linen Packing',
          isParent: false,
          path: `${VRSConst.menuRedirectedURL}/report-linen`,
          module: VRSConst.module.ALLOW_MASTER,
        },
        {
          icon: '',
          name: 'Tasks',
          isParent: false,
          path: `${VRSConst.menuRedirectedURL}/tasks`,
          module: VRSConst.module.ALLOW_MASTER,
        },
        {
          icon: '',
          name: 'Piece Pay Review',
          isParent: false,
          path: `${VRSConst.menuRedirectedURL}/report-piece-pay?showTimeTaken=1&searchStartDate=${get2DaysBeforeDate()}`,
          module: VRSConst.module.ALLOW_PIECE_PAY,
        },
        {
          icon: '',
          name: 'Blended Pay Review',
          isParent: false,
          path: `${VRSConst.menuRedirectedURL}/report-pay-review?showTimeTaken=1&searchStartDate=${get2DaysBeforeDate()}`,
          module: `${VRSConst.module.ALLOW_PIECE_PAY}|${VRSConst.module.ALLOW_TIME_TRACKING}`,
        },
        {
          icon: '',
          name: 'Forecasting',
          isParent: false,
          path: `${VRSConst.menuRedirectedURL}/forecasting`,
          module: VRSConst.module.ALLOW_REPORTS,
        },
      ],
    },
    {
      icon: '',
      name: 'Tracking',
      isParent: false,
      path: `${VRSConst.menuRedirectedURL}/tracking`,
      displayIcon: '',
      module: (!isEmpty(loggedInStaff) && loggedInStaff.loggedInStaffId === 0)
        ? VRSConst.module.ALLOW_TIME_TRACKING
        : (
          !isEmpty(permission) && permission[VRSConst.module.ALLOW_ADMIN_ACCESS]
          && permission[VRSConst.module.ALLOW_TRACKING]
        )
          ? VRSConst.module.ALLOW_TIME_TRACKING : '',
      childNode: [],
    },
    {
      icon: '',
      name: 'Scheduling',
      isParent: false,
      path: `${VRSConst.menuRedirectedURL}/mastercalendar-tasks2`,
      module: VRSConst.module.ALLOW_SCHEDULE_ACCESS,
      childNode: [],
      displayIcon: 'fa fa-calendar',
    },
    {
      icon: '',
      name: 'Schedule',
      displayIcon: 'fa fa-list-ul',
      isParent: false,
      path: `${VRSConst.menuRedirectedURL}/schedule`,
      module: VRSConst.module.ALLOW_SCHEDULE_ACCESS,
      childNode: [],
    },
    {
      icon: '',
      displayIcon: 'fa fa-calendar',
      name: 'Master',
      isParent: false,
      path: `${VRSConst.menuRedirectedURL}/calendar`,
      module: VRSConst.module.ALLOW_MASTER_CALENDER,
      childNode: [],
    },
    {
      icon: '',
      name: 'Bookings',
      isParent: false,
      path: `${VRSConst.menuRedirectedURL}/bookings`,
      module: VRSConst.module.ALLOW_EDIT_BOOKINGS,
      childNode: [],
      displayIcon: '',
    },
    {
      name: 'Filter',
      icon: 'fa fa-filter',
      isParent: true,
      path: '#',
      displayIcon: '',
      module: (!isEmpty(loggedInStaff) && loggedInStaff.loggedInStaffId === 0)
        ? `${VRSConst.module.ALLOW_REGION_GROUP}|${VRSConst.module.ALLOW_PROPERTY_GROUP}`
        : (
          !isEmpty(permission) && (permission[VRSConst.module.ALLOW_MASTER_CALENDER]
            || permission[VRSConst.module.ALLOW_SCHEDULE_ACCESS])
        )
          ? `${VRSConst.module.ALLOW_REGION_GROUP}|${VRSConst.module.ALLOW_PROPERTY_GROUP}`
          : '',
      childNode: [],
    },
  ];
  return SubMenu;
};
