import {openDB} from "idb";
let idbkey = 1; 

const dbPromise = () => openDB('background-sync-db', 1, {
  upgrade(db) {
    db.createObjectStore('requests');
  },
});
const idbKeyval = {
  async get(key) {
    return (await dbPromise()).get('requests', key);
  },
  async set(val) {
    idbkey += 1;
    return (await dbPromise()).put('requests', val, idbkey);
  },
  async delete(key) {
    return (await dbPromise()).delete('requests', key);
  },
  async clear() {
    return (await dbPromise()).clear('requests');
  },
  async keys() {
    return (await dbPromise()).getAllKeys('requests');
  },
};

export default idbKeyval;
